import { Layout } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled(Layout)`
  background: linear-gradient(180deg, #e0daff 0%, #dbb4f1 100%), linear-gradient(0deg, #e0daff, #e0daff);
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100svh;
  overflow-y: auto;
  overflow-x: hidden;
  .app-bg {
    width: 100vw;
    height: 100svh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
  }
  .content {
    width: 100vw;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background: #12161d; */
  }
`;
