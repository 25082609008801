import camelCase from 'lodash/camelCase';
import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { toast } from 'react-toastify';

export const camelCaseKeys = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map((v) => camelCaseKeys(v));
  }
  if (obj !== null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [camelCase(key)]: camelCaseKeys(obj[key])
      }),
      {}
    );
  }
  return obj;
};

export const addressSplit = (addr: string) => {
  return addr?.substr(0, 13) + '...' + addr?.substr(addr?.length - 8, addr?.length);
};

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const getUTCTime = () => {
  const date = new Date();
  const now_utc = Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
  return {
    date: now_utc,
    timeSpan: new Date(now_utc).getTime()
  };
};

export const copyTextToClipboard = async (text: string) => {
  try {
    await navigator.clipboard.writeText(text);
    toast.success('Copied to clipboard!');
  } catch (err) {
    toast.error('Failed to copy!');
  }
};
