import styled from 'styled-components';
import HomeBg from '@/assets/home/home-bg.png';
export const Wrapper = styled.div`
  width: 100vw !important;
  min-height: 100svh;
  background-size: 100% 100%;
  padding: 24px;
  background: url(${HomeBg});
  background-size: cover;
  background-position: center center;
  .highlight-text {
    background: radial-gradient(50% 50% at 50% 50%, #ff3e41 0%, #ffe172 100%);
    font-family: 'Oxanium';
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;
