import styled from 'styled-components';
export const Wrapper = styled.div`
  width: 100vw !important;
  min-height: 100svh;
  background-size: 100% 100%;
  padding: 24px;
  .leaderboard-list {
    max-height: calc(100svh - 210px);
    overflow: auto;
  }
`;
