import { Layout } from '@/components/layout/layout';
import { Wrapper } from './login.styled';
import React, { memo, useState } from 'react';
import { Step0 } from './components/step0';
import { Step1 } from './components/step1';
import { Step2 } from './components/step2';

export const LoginComponent = () => {
  const [step, setStep] = useState(0);
  const [start, setStart] = useState(false);
  const [end, setEnd] = useState(false);
  const [accountAnalize, setAccountAnalize] = useState<any>(undefined);
  return (
    <Layout showStatusBar={false}>
      <Wrapper className='app-container !p-0 flex flex-col items-center justify-between relative gap-5'>
        {step === 0 && (
          <Step0
            setStep={(step: number) => setStep(step)}
            setStart={(step: boolean) => setStart(step)}
            setEnd={(step: boolean) => setEnd(step)}
            setAccountAnalize={(token: any) => setAccountAnalize(token)}
          />
        )}
        {step === 1 && <Step1 setStep={(step: number) => setStep(step)} start={start} end={end} />}
        {step === 2 && <Step2 accountAnalize={accountAnalize} />}
      </Wrapper>
    </Layout>
  );
};
export const Login = memo(LoginComponent);
