import React from 'react';
import Tab1 from '@/assets/navigate/navigate-1.png';
import Tab2 from '@/assets/navigate/navigate-2.png';
// import Tab3 from '@/assets/navigate/navigate-3.png';
// import Tab4 from '@/assets/navigate/navigate-4.png';
import { useNavigate } from 'react-router';
export const HomeTabs = () => {
  const navigate = useNavigate();
  return (
    <div className='w-full grid grid-cols-2 gap-y-4 absolute top-[180px] px-4'>
      <div className='flex w-full justify-start cursor-pointer'>
        <img src={Tab1} alt='' className='w-[35%] max-w-[70px]' />
      </div>
      <div
        className='flex w-full justify-end cursor-pointer'
        onClick={() => {
          navigate('/leaderboard');
        }}
      >
        <img src={Tab2} alt='' className='w-[35%] max-w-[70px]' />
      </div>
      {/* <div className='flex w-full justify-start cursor-pointer'>
        <img src={Tab3} alt='' className='w-[35%] max-w-[70px]' />
      </div>
      <div className='flex w-full justify-end cursor-pointer'>
        <img src={Tab4} alt='' className='w-[35%] max-w-[70px]' />
      </div> */}
    </div>
  );
};
