import React, { memo } from 'react';
import { Wrapper } from './app-loading.styled';

const LoadingComponent = () => {
  return (
    <Wrapper id='loading'>
      <div className='app-container flex flex-col items-start relative w-full h-full !py-6 !px-2'>
        <div className='secondary-font text-[#10051C] font-normal text-[32px] w-[80%] max-w-[350px]'>
          Bring your Hyperdoge to the moon
        </div>
        <div className='flex items-center gap-2 bg-[#FFFFFF] px-4 py-2 rounded-2xl mt-4'>
          <div className='loading-spine'></div>
          <div className='text-[#10051C] font-medium text-sm'>Loading</div>
        </div>
      </div>
    </Wrapper>
  );
};

export const Loading = memo(LoadingComponent);
