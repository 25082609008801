import React from 'react';
import { ReactComponent as CheckIcon } from '@/assets/common-icon/check-circle.svg';
export const Progress = ({ title, value }: { title: string; value: number }) => {
  return (
    <div className='w-full flex items-center gap-5 p-4 step1-progress rounded-lg justify-between'>
      <div className='flex-[0.85] flex flex-col items-start gap-2'>
        <div className='font-medium text-[#1A1224] text-sm'>{title}</div>
        <div className='w-full relative h-[8px] bg-[#E1D6EF]  rounded-3xl overflow-hidden gap-2'>
          <div
            className='h-full bg-[#9454D5] rounded-3xl'
            style={{
              width: `${value}%`
            }}
          ></div>
        </div>
      </div>
      <div className={`check-icon flex-[0.1] flex justify-end ${value === 100 && 'active'}`}>
        <CheckIcon />
      </div>
    </div>
  );
};
