import { Layout } from '@/components/layout/layout';
import { Wrapper } from './referral.styled';
import React, { memo, useMemo } from 'react';
import RoundedLogo from '@/assets/logo/logo-rounded.png';
import { ReactComponent as RefreshIcon } from '@/assets/common-icon/refresh.svg';
import ReferralIcon from '@/assets/tab/referral.png';
import Button from '@/components/button/button';
import { useAccountInfoContext } from '@/contexts/account-info.context';
import { appConfig } from '@/constants/app-constaint';
import { copyTextToClipboard } from '@/utilities/string.utils';
export const ReferralComponent = () => {
  const { referral } = useAccountInfoContext();
  const refLink = useMemo(() => {
    return `${appConfig.twaUrl}?startapp=ref=${referral?.code}`;
  }, [referral]);
  return (
    <Layout>
      <Wrapper className='app-container w-full min-h-[100svh] flex flex-col items-center relative p-4'>
        <div className='secondary-font font-normal text-[32px] text-center text-[#10051C]'>Referral</div>
        <div className='app-linear-border p-4 rounded-lg w-full flex flex-col gap-4 mt-4'>
          <div className='w-full flex'>
            <div className='flex-[0.1]'>
              <img src={RoundedLogo} className='w-10' alt='' />
            </div>
            <div className='flex-[0.8] flex flex-col items-start'>
              <span className='text-[#948E9C] text-sm font-normal'>Recruit Friends</span>
              <div className='text-[#10051C] text-sm font-medium'>
                +25,000 POINTs <span className='text-sm text-[#948E9C] font-normal'>for you and your frends</span>
              </div>
            </div>
          </div>
          <div className='w-full flex justify-between items-center'>
            <div className='w-[80%] flex flex-col items-start'>
              <span className='text-[#948E9C] text-sm font-normal'>Recruit Friends</span>
              <div className='text-[#10051C] text-sm font-medium w-full truncate'>{refLink}</div>
            </div>
            <div className='w-[10%] min-w-[44px] flex justify-end'>
              <div
                className='cursor-pointer copy-button  py-1 px-2 !border-[1px] rounded !bg-[#051928] text-[#FFFFFF] text-xs font-normal text-center flex items-center justify-center'
                onClick={async () => await copyTextToClipboard(refLink || '')}
              >
                Copy
              </div>
            </div>
          </div>
        </div>
        <div className='flex w-full items-center justify-between mt-6'>
          <div className='text-base font-normal text-[#1A1224]'>Total Friens: 0</div>
          <div className='flex items-center gap-2 refresh-icon cursor-pointer'>
            <RefreshIcon />
            <span className='text-[#402957] text-sm font-medium '>Refresh</span>
          </div>
        </div>
        {false ? (
          <div className='app-linear-border p-4 rounded-lg w-full flex flex-col items-center gap-4 mt-2'>
            <img src={ReferralIcon} className='w-[96px]' alt='' />
            <Button>Recruit a Friend</Button>
          </div>
        ) : (
          <div className='app-linear-border p-4 rounded-lg w-full flex flex-col gap-4 mt-4'>
            <div className='w-full py-4 flex justify-between gap-2'>
              <div className='flex-[0.2] flex items-center justify-start'>
                <span className='text-sm text-[#10051C] font-normal'>1</span>
                <span className='mb-1'>🤴</span>
              </div>
              <div className='flex-[0.4] flex items-center justify-start text-start text-sm text-[#10051C] font-normal'>
                Laura Lance
              </div>
              <div className='flex-[0.4] flex items-center justify-end text-end text-base text-[#10051C] font-semibold'>
                10,000,000 PTS
              </div>
            </div>
          </div>
        )}
      </Wrapper>
    </Layout>
  );
};
export const Referral = memo(ReferralComponent);
