import React, { useState } from 'react';
import MapGraphics from '@/assets/login/map-graphics.png';
import { Progress } from './progress';
import Button from '@/components/button/button';
import { useQuery } from '@tanstack/react-query';
export const Step1 = ({ setStep, start, end }: { setStep: (step: number) => void; start: boolean; end: boolean }) => {
  const [value, setValue] = useState(0);

  useQuery({
    queryKey: [`login-progress`],
    queryFn: () => {
      if (!start) return -1;
      const addedValue = Math.floor(Math.random() * 10);
      if (!end) {
        setValue(Math.min(value + addedValue, 398));
      } else {
        setValue(Math.min(value + addedValue, 400));
      }
      return -1;
    },
    retry: !!start && value < 400,
    refetchInterval: 200
  });
  return (
    <div className='flex flex-col items-center justify-between relative gap-5 h-full w-full min-h-[100svh]'>
      <div className='w-full px-4 pt-12 relative z-[2]'>
        <div className='secondary-font font-normal text-[32px] text-center text-[#402957]'>Checking your account</div>
        <div className='w-full flex flex-col gap-3 mt-[58px]'>
          <Progress title='Account Age Verified' value={Math.min(value, 100)} />
          <Progress title='Activity Level Analyzed' value={Math.min(Math.max(0, value - 100), 100)} />
          <Progress title='Telegram Premium Checked' value={Math.min(Math.max(0, value - 200), 100)} />
          <Progress title='OG Status Confirmed' value={Math.min(Math.max(0, value - 300), 100)} />
        </div>
      </div>
      <div className='w-full p-4 relative z-[2]'>
        <Button
          disabled={value < 400}
          onClick={() => {
            setStep(2);
          }}
        >
          Continue
        </Button>
      </div>
      <img src={MapGraphics} className='bottom-0 z-[1] w-full absolute left-0' alt='' />
    </div>
  );
};
