import React, { useCallback, useMemo, useState } from 'react';
import Gem from '@/assets/logo/gems.png';
import Fire from '@/assets/common-icon/fire.png';
import { useAccountInfoContext } from '@/contexts/account-info.context';
import { showNumber } from '@/utilities/number.utils';
import { useQuery } from '@tanstack/react-query';
import Button from '@/components/button/button';
import { ClaimPopup } from '@/components/layout/components/claim';
import { useOnOff } from '@/hooks/use-on-off';
export const HomeHeader = () => {
  const [addedPoint, setAddedPoint] = useState(0);
  const { userProfile, claimableReward, latestUpdate, isClaimShowPopup } = useAccountInfoContext();
  const { isOn: isClaim, turnOn: turnOnClaim, turnOff: turnOffClaim } = useOnOff(false);

  const estimatePoint = useCallback(() => {
    const point = Number(userProfile?.point);
    const coreValue = userProfile?.boost_core_value;
    const storageValue = userProfile?.boost_storage_value;
    const multipleValue = userProfile?.boost_multiple_value;

    const timeNow = new Date();
    const timeDiff = timeNow.getTime() - (latestUpdate || new Date().getTime());
    const timeDiffInHours = timeDiff / (1000 * 3600);
    const timeCanCount = Math.min(timeDiffInHours, storageValue);
    console.log('timeCanCount', timeCanCount, timeDiffInHours, storageValue);
    console.log('coreValue', coreValue);
    console.log('multipleValue', multipleValue);

    const pointCanClaim = coreValue * timeCanCount * multipleValue;
    return Math.floor(pointCanClaim) || 0;
  }, [userProfile, latestUpdate]);
  const countInterval = useMemo(() => {
    if (addedPoint === 0) return 500;
    return (new Date().getTime() - latestUpdate) / addedPoint;
  }, [addedPoint, latestUpdate]);
  useQuery({
    queryKey: [`estimateUserPoint`, estimatePoint],
    queryFn: () => {
      setAddedPoint(estimatePoint());
      return -1;
    },
    retry: false,
    refetchInterval: countInterval,
    enabled: !!userProfile && !isClaimShowPopup
  });

  // const countInterval = useMemo(() => {
  //   return (new Date().getTime() - latestUpdate) / addedPoint;
  // }, [addedPoint, latestUpdate]);

  // useQuery({
  //   queryKey: [`countPoint`, countInterval],
  //   queryFn: () => {
  //     setCount(count + 1);
  //     return -1;
  //   },
  //   retry: false,
  //   refetchInterval: countInterval,
  //   enabled: !!userProfile
  // });

  console.log('es', addedPoint);
  return (
    <div className='flex flex-col items-center relative w-full'>
      {isClaim && <ClaimPopup onClose={turnOffClaim} />}
      <span className='text-[#695481] font-normal text-sm'>In storage</span>
      <div className='flex items-center gap-[5px]'>
        <img src={Gem} className='w-10' alt='' />
        <span className='text-[#10051C] font-medium text-[40px]'>
          {showNumber(Number(userProfile?.point || 0) + claimableReward?.point + addedPoint)}
        </span>
        <Button size={'sm'} onClick={turnOnClaim}>
          Claim
        </Button>
      </div>
      <div className='flex items-center gap-1'>
        <span className='text-sm font-normal text-[#695481]'>XP Balance:</span>
        <img src={Gem} className='w-6' alt='' />
        <span className='text-[#10051C] font-medium text-sm'>{showNumber(Number(userProfile?.point || 0))}</span>
      </div>
      <div className='mt-2 h-8 overflow-visible flex items-center justify-center bg-[#10051C] rounded-3xl'>
        <img src={Fire} className='w-10 h-10 self-end' alt='' />
        <span className='highlight-text'>X{userProfile?.boost_multiple_value}</span>
        <span className='text-sm text-[#FFFFFF] font-medium ml-1 mr-2'>Speed</span>
      </div>
    </div>
  );
};
