import React, { useState } from 'react';
import MapGraphics from '@/assets/login/map-graphics-light.png';
import Button from '@/components/button/button';
import Dog from '@/assets/login/dog.png';
import { accountRate } from '@/constants/app-constaint';
import { useLoginWithTelegram } from '@/hooks/use-telegram-login';
import WebApp from '@twa-dev/sdk';
import { useAccountInfoContext } from '@/contexts/account-info.context';
export const Step2 = ({ accountAnalize }: { accountAnalize: any }) => {
  const [active, setActive] = useState(false);
  const ref = WebApp.initDataUnsafe.start_param;
  const { refetchAccountExist } = useAccountInfoContext();
  const { handleLogin, isLoadingLogin } = useLoginWithTelegram();
  console.log('accountAnalize', accountAnalize);
  return (
    <div className='flex flex-col items-center justify-between relative gap-5 h-full w-full min-h-[100svh] overflow-hidden'>
      {!active ? (
        <>
          <div className='w-full px-4 pt-10 flex flex-col items-center relative z-[2]'>
            <div className='w-full flex items-center h-2 gap-[5px]'>
              <div className={`flex-1 h-full rounded-3xl bg-[#EFEDF1] ${true && '!bg-[#9454D5]'}`}></div>
              <div className={`flex-1 h-full rounded-3xl bg-[#EFEDF1] ${false && '!bg-[#9454D5]'}`}></div>
            </div>
            <div className='mt-4 secondary-font text-[32px] text-[#402957] text-center font-normal'>Elite member</div>
            <div className='mt-2 font-normal text-[#402957] text-base text-center'>You have joined Telegram</div>
          </div>
          <div className='w-full flex flex-col items-center gap-2 overflow-visible relative z-[1]'>
            <div className='mt-[50px] secodary-font text-[256px] font-normal text-[#10051C] px-4 relative z-[2] leading-[170px] text-end flex justify-end'>
              {accountAnalize?.account_age}
            </div>
            <div className='text-[#402957] text-[32px] secodary-font font-normal px-4 relative z-[2] '>Years Ago</div>
            <img src={MapGraphics} className='w-full absolute top-[30px] left-0 z-[1]' alt='' />
          </div>
          <div className='w-full flex flex-col items-center gap-6 p-4 relative z-[2]'>
            <div className='text-[#402957] text-base font-normal text-center'>
              Your account number is #{accountAnalize?.user?.id || '...'}.
              <br /> You’re in the Top {accountRate[accountAnalize?.account_age] || '10%'} Telegram Users
            </div>
            <Button
              onClick={() => {
                setActive(true);
              }}
            >
              Continue
            </Button>
          </div>
        </>
      ) : (
        <>
          <div className='w-full px-4 pt-10 flex flex-col items-center relative z-[2]'>
            <div className='w-full flex items-center h-2 gap-[5px]'>
              <div className={`flex-1 h-full rounded-3xl bg-[#EFEDF1] ${true && '!bg-[#9454D5]'}`}></div>
              <div className={`flex-1 h-full rounded-3xl bg-[#EFEDF1] ${true && '!bg-[#9454D5]'}`}></div>
            </div>
            <div className='mt-4 secondary-font text-[32px] text-[#402957] text-center font-normal'>
              You are amazing
            </div>
            <div className='mt-2 font-normal text-[#402957] text-base text-center'>Here is your Alpaca reward!</div>
          </div>
          <div className='w-full flex flex-col items-center gap-2 overflow-visible relative z-[1]'>
            <img src={Dog} className='w-full' alt='' />
          </div>
          <div className='w-full flex flex-col items-center gap-6 p-4 relative z-[2]'>
            <div className='text-[#402957] text-base font-normal text-center'>
              Your account number is #{accountAnalize?.user?.id || '...'}.
              <br /> You’re in the Top {accountRate[accountAnalize?.account_age] || '10%'} Telegram Users
            </div>
            <Button
              isLoading={isLoadingLogin}
              onClick={async () => {
                await handleLogin(accountAnalize?.point, ref);
                refetchAccountExist();
              }}
            >
              Continue
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
