import { UserRepository } from '@/repository/user.repository';
import WebApp from '@twa-dev/sdk';
import { useState } from 'react';
export const useCheckAccountFirstLogin = () => {
  const [isLoading, setIsLoading] = useState(false);
  const handleCheckAccountFirstLogin = async () => {
    setIsLoading(true);
    const tonOauthResponse = await UserRepository.checkAccountInfoFirstLogin(
      WebApp.initData ||
        'query_id=AAEATshbAgAAAABOyFsiQAxE&user=%7B%22id%22%3A5834821120%2C%22first_name%22%3A%22Giang%22%2C%22last_name%22%3A%22Nguyen%22%2C%22language_code%22%3A%22en%22%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1722503457&hash=efa6e72cb634474b44906f0eea07e06187a9ec8efd91c4d65f516096af0dfa5d'
    );
    setIsLoading(false);
    return tonOauthResponse;
  };

  return {
    handleCheckAccountFirstLogin,
    isLoading
  };
};
