import Button from '@/components/button/button';
import { useAccountInfoContext } from '@/contexts/account-info.context';
import React from 'react';
import { useNavigate } from 'react-router';
export const HomeInfo = () => {
  const navigate = useNavigate();
  const { userProfile } = useAccountInfoContext();
  return (
    <div className='w-full  absolute bottom-[100px] px-4'>
      <div className='w-full grid grid-cols-3 gap-y-4 p-3 app-linear-border rounded-2xl'>
        <div className='flex flex-col items-center'>
          <span className='text-[#948E9C] font-normal text-xs'>Core level {userProfile?.boost_core_level + 1}</span>
          <div className='text-sm font-normal text-[#10051C] flex items-center gap-1'>
            <span className='font-semibold text-[#9454D5]'>{userProfile?.boost_core_value}</span>
            hype/hr
          </div>
          <Button
            size={'sm'}
            onClick={() => {
              navigate('/boost?type=Core');
            }}
          >
            Upgrade
          </Button>
        </div>
        <div className='flex flex-col items-center'>
          <span className='text-[#948E9C] font-normal text-xs'>
            Storage level {userProfile?.boost_storage_level + 1}
          </span>
          <div className='text-sm font-normal text-[#10051C] flex items-center gap-1'>
            <span className='font-semibold text-[#9454D5]'>{userProfile?.boost_storage_value}</span>
            claim/hr
          </div>
          <Button
            size={'sm'}
            onClick={() => {
              navigate('/boost?type=Storage');
            }}
          >
            Upgrade
          </Button>
        </div>
        <div className='flex flex-col items-center'>
          <span className='text-[#948E9C] font-normal text-xs'>Multiple level {userProfile?.boost_multiple_level}</span>
          <div className='text-sm font-normal text-[#10051C] flex items-center gap-1'>
            <span className='font-semibold text-[#9454D5]'>{userProfile?.boost_multiple_value}</span>
            speed
          </div>
          <Button
            size={'sm'}
            onClick={() => {
              navigate('/boost?type=Multiple');
            }}
          >
            Upgrade
          </Button>
        </div>
      </div>
    </div>
  );
};
