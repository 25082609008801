import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  height: fit-content;
  width: 100vw;
  z-index: 99;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  .app-container {
    background: #ffffff;
    border: 2px solid var(--Purple-100, #402957);
    border-radius: 24px 24px 0px 0px;
    border-bottom: none;
  }
`;
