import styled from 'styled-components';
export const Wrapper = styled.div`
  width: 100vw !important;
  min-height: 100svh;
  background-size: 100% 100%;
  padding: 24px;
  .copy-button {
    background:
      linear-gradient(#051928 0 0) padding-box,
      linear-gradient(to bottom, #e7b4fe, #8608b2) border-box;
    border: 2px solid transparent;
  }
  /* .refresh-icon {
    svg,
    g,
    path {
      stroke: #9454d5;
    }
  } */
`;
