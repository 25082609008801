import styled from 'styled-components';
export const Wrapper = styled.div`
  width: 100vw !important;
  min-height: 100svh;
  background-size: 100% 100%;
  padding: 24px;
  .step1-progress {
    border: 2px solid #e7b4fe;
    box-shadow: 0px 4px 4px 0px #ffffff0d inset;
    background: var(--White-100, #ffffff);

    .check-icon {
      &.active {
        svg,
        g,
        path {
          stroke: #9454d5;
        }
      }
    }
  }
`;
