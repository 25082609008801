import React, { memo, useState } from 'react';
import { Wrapper } from './daily-checkin.styled';
import Daily from '@/assets/quest/daily.png';
import { ReactComponent as CloseIcon } from '@/assets/common-icon/close-ic.svg';
import { ReactComponent as CheckIcon } from '@/assets/common-icon/check-ic.svg';
import Button from '@/components/button/button';
const DailyCheckinComponent = ({ handleClose }: { handleClose: () => void }) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const today = 3;
  const dailyList = [
    {
      day: 'Day 1',
      reward: '500',
      checkin: true
    },
    {
      day: 'Day 1',
      reward: '500',
      checkin: true
    },
    {
      day: 'Day 1',
      reward: '500',
      checkin: true
    },
    {
      day: 'Day 1',
      reward: '500',
      checkin: false
    },
    {
      day: 'Day 1',
      reward: '500',
      checkin: true
    },
    {
      day: 'Day 1',
      reward: '500',
      checkin: true
    },
    {
      day: 'Day 1',
      reward: '500',
      checkin: true
    },
    {
      day: 'Day 1',
      reward: '500',
      checkin: true
    },
    {
      day: 'Day 1',
      reward: '500',
      checkin: true
    }
  ];
  return (
    <Wrapper>
      <div className='w-full app-container flex flex-col items-center bg-[#FFFFFF] app-linear-border daily-popup'>
        <CloseIcon className='self-end -mt-4 -mr-4 cursor-pointer' onClick={handleClose} />
        <img src={Daily} className='w-20' alt='' />
        <div className='font-semibold text-[#10051C] text-base mt-6'>Daily Reward</div>
        <div className='mt-2 text-[#10051C] text-sm font-medium text-center'>
          Accrue POINTs for logging into the game daily without skipping
        </div>
        <div className='mt-2 w-full p-4 bg-[#EFEDF1] rounded-2xl grid grid-cols-2'>
          <div className='flex flex-col items-start'>
            <div className='text-sm font-normal text-[#948E9C]'>Checked in</div>
            <div className='text-sm font-medium text-[#10051C]'>2/10 days</div>
          </div>
          <div className='flex flex-col items-start'>
            <div className='text-sm font-normal text-[#948E9C]'>You earned</div>
            <div className='text-sm font-medium text-[#10051C]'>1,5K POINTs</div>
          </div>
        </div>
        <div className='w-full grid grid-cols-4 gap-1 mt-6'>
          {dailyList.map((daily, key) => {
            return (
              <div
                key={key}
                className={`w-full py-2 flex flex-col items-center app-linear-border rounded-xl gap-1 ${key === today && !daily.checkin && 'today'}`}
              >
                <div className='flex items-center gap-1 '>
                  {daily.checkin && key <= today && <CheckIcon className='check-icon' />}
                  <span className='text-[#948E9C] text-sm font-normal'>{daily.day}</span>
                </div>
                <div className='text-base font-medium text-[#10051C]'>{daily.reward}</div>
              </div>
            );
          })}
        </div>
        <Button className='w-full mt-9'>Check In</Button>
      </div>
    </Wrapper>
  );
};

export const DailyCheckin = memo(DailyCheckinComponent);
