import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectToken } from '@/redux';
import { useQuery } from '@tanstack/react-query';
import { UserRepository } from '@/repository/user.repository';
import { lowerCacheOptions } from '@/constants/app-constaint';
import WebApp from '@twa-dev/sdk';
import { useLoginWithTelegram } from '@/hooks/use-telegram-login';
export interface IAccountInfoContext {
  userProfile: any;
  refetchProfile: () => void;
  setFirstLogin: (is: boolean) => void;
  firstLogin: boolean;
  isAccountExist: any;
  refetchAccountExist: () => void;
  referral: any;
  allBoosts: any;
  refetchReward: () => void;
  claimableReward: any;
  latestUpdate: number;
  isClaimShowPopup: boolean;
}
const AccountInfoContext = React.createContext<IAccountInfoContext | null>(null);
export const AccountInfoContextProvider = ({ children }: React.PropsWithChildren) => {
  const [latestUpdate, setLatestUpdate] = React.useState(0);
  console.log('init', WebApp);
  const token = useSelector(selectToken);
  const [firstLogin, setFirstLogin] = React.useState(true);
  const { data: isAccountExist, refetch: refetchAccountExist } = useQuery({
    queryKey: ['checkAccountExist'],
    queryFn: () =>
      UserRepository.checkAccountExist(
        WebApp.initData ||
          'query_id=AAG07mw4AwAAALTubDj_ylax&user=%7B%22id%22%3A7389114036%2C%22first_name%22%3A%22CPSHCM_536XVNT_TVKHO_KIET%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22KIET_KHO_536XVNT%22%2C%22language_code%22%3A%22en%22%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1726193805&hash=bfb60eafd8d50734bb51739836d11e66d4c7490a49304d2d8e709664f9d63484'
      ),
    retry: false,
    // enabled: !!WebApp.initData,
    ...lowerCacheOptions,
    refetchOnMount: 'always'
  });
  const { handleLogin } = useLoginWithTelegram();

  console.log('isAccountExist', isAccountExist);

  const { data: userProfile, refetch: refetchProfile } = useQuery({
    queryKey: ['retrieveProfile', token],
    queryFn: () => UserRepository.getUserProfile(),
    retry: false,
    enabled: !!token,
    ...lowerCacheOptions,
    refetchOnMount: 'always',
    refetchInterval: 60000
  });

  const { data: referral } = useQuery({
    queryKey: ['getReferral', token],
    queryFn: () => UserRepository.getReferral(),
    retry: false,
    ...lowerCacheOptions,
    enabled: !!token,
    refetchOnMount: 'always'
  });

  const { data: allBoosts } = useQuery({
    queryKey: ['getAllBoosts', token],
    queryFn: () => UserRepository.getAllBoosts(),
    retry: false,
    ...lowerCacheOptions,
    enabled: !!token,
    refetchOnMount: 'always'
  });

  const { data: claimableReward, refetch: refetchReward } = useQuery({
    queryKey: ['getClaimableReward', token],
    queryFn: () => {
      const res = UserRepository.estimateClaim();
      setLatestUpdate(new Date().getTime());
      return res;
    },
    retry: false,
    ...lowerCacheOptions,
    enabled: !!token,
    refetchOnMount: 'always',
    refetchInterval: 100000
  });

  const isClaimShowPopup = React.useMemo(() => {
    console.log('time diff', new Date().getTime() - (new Date(userProfile?.last_updated)?.getTime() || 99999999999999));
    return (
      new Date().getTime() - (new Date(userProfile?.last_updated)?.getTime() || 99999999999999) >
      (userProfile?.boost_storage_value || 1) * 60 * 60 * 1000
    );
  }, [userProfile]);

  const value = {
    setFirstLogin,
    firstLogin,
    userProfile,
    refetchProfile,
    isAccountExist,
    refetchAccountExist,
    referral,
    allBoosts,
    refetchReward,
    claimableReward,
    latestUpdate,
    isClaimShowPopup
  };

  React.useEffect(() => {
    if (isAccountExist?.existed && !token) {
      handleLogin();
    }
  }, [isAccountExist, token]);

  console.log('token', token);

  return <AccountInfoContext.Provider value={value}>{children}</AccountInfoContext.Provider>;
};

// eslint-disable-next-line react-refresh/only-export-components
export const useAccountInfoContext = (): IAccountInfoContext => {
  const context = React.useContext(AccountInfoContext);
  if (context === undefined) {
    throw new Error('useAccountInfoContext Error');
  }
  return context as IAccountInfoContext;
};
