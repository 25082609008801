import styled from 'styled-components';
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  .boost-popup {
    padding: 24px;
    border-radius: 16px 16px 0px 0px;
    box-shadow: 0px 4px 4px 0px #ffffff0d inset;
    .today {
      background:
        linear-gradient(#e1d6ef 0 0) padding-box,
        linear-gradient(to bottom, #e7b4fe, #8608b2) border-box;
    }
    .check-icon {
      &svg,
      g,
      path {
        stroke: #00e1b7;
      }
    }
  }
`;
