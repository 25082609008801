/* eslint-disable @typescript-eslint/no-explicit-any */
import isEmpty from 'lodash/isEmpty';

interface IENVS {
  VITE_BASE_API: string;
  VITE_ISTESTNET: boolean;
  VITE_TON_BOT: string;
}

const defaultEnvs: IENVS = {
  VITE_BASE_API: '',
  VITE_ISTESTNET: true,
  VITE_TON_BOT: ''
};

export const getEnvs = () => {
  let envs: any = {};
  try {
    const ENVS = import.meta.env;
    if (!isEmpty(ENVS)) {
      Object.keys(ENVS).forEach((key: string) => {
        const value = ENVS[key];
        if (key === 'VITE_BASE_CHAIN_ID') {
          envs[key] = ENVS[key]?.split(',');
        } else {
          if (value === 'true' || value === 'false') {
            envs[key] = value === 'true';
          } else {
            envs[key] = ENVS[key];
          }
        }
        return key;
      });
    }
  } catch (error) {
    console.debug(error);
  } finally {
    envs = isEmpty(envs) ? defaultEnvs : envs;
  }
  return { ...envs };
};

export const ENVS: IENVS = getEnvs();
