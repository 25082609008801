export const showNumber = (number?: number) => {
  if (!number && number !== 0) return '...';
  if (number === 0) return '0.00';
  if (number < 0.01) return '<0.01';
  return number?.toLocaleString('en', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  });
};

export function nFormatter(num: number, digits: any) {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' }
  ];
  const regexp = /\.0+$|(?<=\.[0-9]*[1-9])0+$/;
  let index = 0;
  for (let i = 0; i < 7; i++) {
    if (num > lookup[i]?.value) index = i;
  }
  const item = lookup?.[index];
  return item ? (num / item.value).toFixed(digits).replace(regexp, '').concat(item.symbol) : '0';
}
