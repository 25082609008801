import React from 'react';
import Dog from '@/assets/login/dog.png';
import Button from '@/components/button/button';
import Star from '@/assets/common-icon/star.png';
import { useCheckAccountFirstLogin } from '../hooks/use-check-account-first-login';
import { toast } from 'react-toastify';
import WebApp from '@twa-dev/sdk';
export const Step0 = ({
  setStep,
  setStart,
  setEnd,
  setAccountAnalize
}: {
  setStep: (step: number) => void;
  setStart: (start: boolean) => void;
  setEnd: (end: boolean) => void;
  setAccountAnalize: (res: any) => void;
}) => {
  const { handleCheckAccountFirstLogin, isLoading } = useCheckAccountFirstLogin();
  return (
    <div className='flex flex-col items-center justify-between relative gap-5 h-full w-full min-h-[100svh]'>
      <img src={Star} className='w-[13.2%] absolute top-4 right-20 z-[2]' alt='' />
      <img src={Star} className='w-[13.2%] absolute top-20 left-20 z-[2]' alt='' />
      <img src={Star} className='w-[13.2%] absolute top-[38%] right-4 z-[2]' alt='' />
      <img src={Star} className='w-[13.2%] absolute top-[30%] left-4 z-[2]' alt='' />
      <div className='h-[0px] max-[420px]:h-[50px] w-full'></div>
      <div className='w-full relative'>
        <img src={Dog} className='w-full' alt='' />
        {/* <img src={Message} className='absolute left-[4%] top-[-21%] w-[41.5%]' alt='' /> */}
      </div>
      <div className='flex flex-col items-center p-4 gap-[10px]'>
        <span className='text-[#402957] font-medium text-[20px] text-center'>
          Hey! <br />
          You’ve been in Telegram for a while, it’s time to get rewarded!
        </span>
        <Button
          isLoading={isLoading}
          onClick={async () => {
            try {
              const res = await handleCheckAccountFirstLogin();
              setAccountAnalize({
                ...res,
                user: WebApp?.initDataUnsafe?.user
              });
              setStart(true);
              setEnd(true);
              setStep(1);
            } catch (err: any) {
              toast.error(err?.message || err || 'Something went wrong!');
            }
          }}
        >
          Wow Let’s Go
        </Button>
      </div>
    </div>
  );
};
