import React, { memo, useCallback, useState } from 'react';
import { Wrapper } from './claim.styled';
import Button from '@/components/button/button';
import { UserRepository } from '@/repository/user.repository';
import { toast } from 'react-toastify';
import { useAccountInfoContext } from '@/contexts/account-info.context';
import Icon from '@/assets/boost/claim.png';
import { useNavigate } from 'react-router';
import { ReactComponent as CloseIcon } from '@/assets/common-icon/close-ic.svg';
import Gem from '@/assets/logo/gems.png';
const ClaimPopupComponent = ({ onClose }: { onClose?: () => void }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { refetchProfile, refetchReward, claimableReward, isClaimShowPopup } = useAccountInfoContext();
  const navigate = useNavigate();
  const handleClaimReward = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = await UserRepository.claim();
      if (res) {
        toast.success('Claimed Reward successfully!');
        refetchProfile();
        refetchReward();
        setIsLoading(false);
        if (onClose) {
          onClose();
        }
      }
    } catch (error: any) {
      toast.error(error?.message || error || 'Something went wrong! Try again later!');
      setIsLoading(false);
    }
  }, [refetchProfile, refetchReward]);

  return (
    <Wrapper>
      <div className='w-full app-container flex flex-col items-center bg-[#FFFFFF] app-linear-border claim-popup'>
        {!!onClose && (
          <div onClick={onClose} className='self-end cursor-pointer'>
            <CloseIcon />
          </div>
        )}
        <img src={Icon} className='w-[96px]' alt='' />
        <div className='font-medium text-[#10051C] text-base mt-6'>
          {isClaimShowPopup ? 'Your storage is full' : ''}
        </div>
        {isClaimShowPopup && (
          <div className='font-normal text-sm text-[#695481] text-center'>
            You cannot earn more HYPE for now. Claim your HYPE or try to upgrade your storage
          </div>
        )}
        <div className='mt-1 text-[#10051C] text-sm font-medium text-center flex items-center'>
          Reward:{' '}
          <span className='font-bold text-[#10051C] ml-1 mr-[2px]'>{claimableReward?.point?.toLocaleString('en')}</span>{' '}
          <img src={Gem} className='w-6 mb-1' alt='' />
        </div>

        <Button className='w-full mt-6' onClick={handleClaimReward} isLoading={isLoading}>
          Claim now
        </Button>
        <Button
          className='w-full mt-2 mb-2'
          variant={'secondary'}
          onClick={() => {
            if (onClose) {
              onClose();
            }
            if (isClaimShowPopup) {
              toast.info('Please claim first!');
            }
            navigate('/boost?type=Storage');
          }}
        >
          Upgrade Storage
        </Button>
      </div>
    </Wrapper>
  );
};

export const ClaimPopup = memo(ClaimPopupComponent);
