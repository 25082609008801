import { Layout } from '@/components/layout/layout';
import { Wrapper } from './leaderboard.styled';
import React, { memo } from 'react';
import Top1 from '@/assets/leaderboard/top-1.png';
import Top2 from '@/assets/leaderboard/top-2.png';
import Top3 from '@/assets/leaderboard/top-3.png';

export const LeaderboardComponent = () => {
  const top = [Top1, Top2, Top3];
  const leaderboard = [
    {
      rank: 1,
      name: 'Darlene Robertson',
      pts: '10,000,000 POINTs'
    },
    {
      rank: 2,
      name: 'Darlene Robertson',
      pts: '10,000,000 POINTs'
    },
    {
      rank: 3,
      name: 'Darlene Robertson',
      pts: '10,000,000 POINTs'
    },
    {
      rank: 4,
      name: 'Darlene Robertson',
      pts: '10,000,000 POINTs'
    },
    {
      rank: 5,
      name: 'Darlene Robertson',
      pts: '10,000,000 POINTs'
    },
    {
      rank: 4,
      name: 'Darlene Robertson',
      pts: '10,000,000 POINTs'
    },
    {
      rank: 5,
      name: 'Darlene Robertson',
      pts: '10,000,000 POINTs'
    },
    {
      rank: 4,
      name: 'Darlene Robertson',
      pts: '10,000,000 POINTs'
    },
    {
      rank: 5,
      name: 'Darlene Robertson',
      pts: '10,000,000 POINTs'
    },
    {
      rank: 4,
      name: 'Darlene Robertson',
      pts: '10,000,000 POINTs'
    },
    {
      rank: 5,
      name: 'Darlene Robertson',
      pts: '10,000,000 POINTs'
    },
    {
      rank: 4,
      name: 'Darlene Robertson',
      pts: '10,000,000 POINTs'
    },
    {
      rank: 5,
      name: 'Darlene Robertson',
      pts: '10,000,000 POINTs'
    }
  ];
  return (
    <Layout>
      <Wrapper className='app-container flex flex-col items-center relative gap-5'>
        <div className='secondary-font font-normal text-[32px] text-center text-[#10051C]'>Leaderboard</div>
        <div className='w-full relative'>
          <div className='w-full app-linear-border px-4 bg-[#FFFFFF] rounded-lg pb-[50px] relative leaderboard-list'>
            {leaderboard.map((ld, key) => {
              return (
                <>
                  <div className='w-full flex justify-between py-4 items-center' key={key}>
                    <div className='flex-1 flex items-center gap-2'>
                      {top[ld.rank - 1] ? (
                        <img src={top[ld.rank - 1]} className='w-10' alt='' />
                      ) : (
                        <div className='w-10 text-center text-sm font-normal text-[#10051C]'>{ld.rank}</div>
                      )}
                      <div className='text-sm font-normal text-[#10051C]'>{ld.name}</div>
                    </div>
                    <div className='text-sm font-medium text-[#10051C]'>{ld.pts}</div>
                  </div>
                  <div className='w-full h-[1px] bg-[#EFEDF1]'></div>
                </>
              );
            })}
          </div>
          <div className='absolute w-[99%] bg-[#E1D6EF] left-[2px] bottom-[2px] rounded-lg  flex justify-between p-4 items-center'>
            <div className='flex-1 flex items-center gap-2'>
              <div className='w-10 text-center text-sm font-normal text-[#10051C]'>80</div>
              <div className='text-sm font-normal text-[#10051C]'>(You) Thomas Wayne</div>
            </div>
            <div className='text-sm font-medium text-[#10051C]'>112 POINTs</div>
          </div>
        </div>
      </Wrapper>
    </Layout>
  );
};
export const Leaderboard = memo(LeaderboardComponent);
